<style lang="less" scoped>
#project-detail {
  .tab-top {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .tab-top-line {
    width: 100%;
    height: 2px;
    background-color: #F7F8FA;
    margin-top: 12px;
  }
  .project-info {
    margin: 30px 40px 60px 40px;
    ::v-deep .ant-form-item {
      margin-bottom: 15px ;
      height: 46px;
    }
    ::v-deep .ant-form-item-label {
      width: 100px ;
    }
    ::v-deep .ant-form-item-label,
    label {
      color: #666666;
      font-size: 16px ;
    }
    ::v-deep .ant-form-item-label > label:after {
      content: "" ;
    }
    ::v-deep .ant-form-item-control-wrapper {
      display: inline-block ;
      margin-left: 5px ;
    }
    ::v-deep .ant-form-item-control-wrapper,
    ::v-deep .ant-form-item-control,
    ::v-deep .ant-form-item-children,
    ::v-deep .ant-calendar-picker,
    ::v-deep .ant-calendar-picker-input {
      height: 46px;
    }
    ::v-deep .ant-form-item-control-wrapper,
    ::v-deep .ant-form-item-control,
    ::v-deep .ant-form-item-children,
    ::v-deep .ant-cascader-picker,
    ::v-deep .ant-cascader-input {
      height: 46px;
      width: 350px;
    }
    ::v-deep .ant-form-item-control-wrapper,
    ::v-deep .ant-form-item-children, input {
      height: 46px;
      line-height: 46px;
    }
    ::v-deep .ant-form-item-control-wrapper,
    ::v-deep .ant-form-item-control,
    ::v-deep .ant-form-item-children,
    ::v-deep .ant-select-enabled,
    ::v-deep .ant-select-selection,
    ::v-deep .ant-select-selection__rendered {
      height: 46px;
      line-height: 46px;
    }
    .flex-vw {
      display: flex;
      ::v-deep .ant-form-item-control-wrapper,
      ::v-deep .ant-form-item-control,
      ::v-deep .ant-form-item-children,
      ::v-deep .ant-cascader-picker,
      ::v-deep .ant-cascader-input {
        width: 200px;
      } 
      .cus-left {
        margin-left: 80px;
      }
    }
    .tips-div {
      margin-left: 28px;
      margin-top: 30px;
      display: flex;
      .tips-title {
        color: rgba(0, 0, 0, 0.85);
        font-size: 15px;
        width: 110px;
      }
      .tips-list {
        display: inline-block;
        ::v-deep .ant-checkbox-wrapper + .ant-checkbox-wrapper {
          margin-left: 0;
        }
      }
    }
  }
  .save-button {
    width: 480px;
    height: 60px;
    margin-top: 50px;
    margin-left: 30px;
    border-radius: 4px;
    background-color: #447df5;
    color: white;
    font-size: 24px;
    font-weight: bold;
  }
}
</style>
<template>
  <div class="project-detail" id="project-detail">
    <div class="tab-top">
      <table-title />
      <project-filter style="margin-top: -5px" :filterJson="filterData" @filterHandler="filterHandler"></project-filter>
    </div>
    <div class="tab-top-line"></div>
    <div class="project-info">
      <template>
        <a-form-model ref="infoForm" :model="form" :rules="rules">
          <a-form-model-item label="项目标题" prop="project_title" ref="project_title">
            <a-input v-model="form.project_title" placeholder="请输入项目标题"/>
          </a-form-model-item>
          <div class="flex-vw">
            <a-form-model-item label="抽样类型" prop="sample_type" ref="sample_type">
              <a-select v-model="form.sample_type" placeholder="请选择抽样类型">
                <a-select-option :key="1">原方式抽样</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="链接类型" prop="url_type" ref="url_type" class="cus-left">
              <a-select v-model="form.url_type" placeholder="请选择链接类型">
                <a-select-option v-for="item in urlTypeData" :key="item.id">{{ item.name }} </a-select-option>
              </a-select>
            </a-form-model-item>
            <!-- <a-form-model-item label="单链接" prop="single_url" ref="single_url" class="cus-left" v-show="form.url_type == 1">
              <a-input  placeholder="请输入单链接"/>
            </a-form-model-item>
            <a-form-model-item label="" prop="more_url" ref="more_url" class="cus-left" v-show="form.url_type == 2">
              <a-upload
                name="file"
                action=""
                @change="uploadhandleChange"
              >
                <a-button> <a-icon type="upload" />上传多链接文件</a-button>
              </a-upload>
            </a-form-model-item> -->
          </div>
          <div class="flex-vw">
            <a-form-model-item label="项目形式" prop="project_form" ref="project_form">
              <a-select v-model="form.project_form" placeholder="请选择项目形式">
                <a-select-option v-for="item in projectFormData" :key="item.id">{{ item.name }} </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="项目类型" prop="project_type" ref="project_type" class="cus-left">
              <a-select v-model="form.project_type" placeholder="请选择项目类型">
                <a-select-option v-for="item in projectTypeData" :key="item.id">{{ item.name }} </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="项目URL类型" prop="project_url_type" ref="project_url_type" class="cus-left">
              <a-select v-model="form.project_url_type" placeholder="请选择项目URL类型">
                <a-select-option :key="1">默认</a-select-option>
                <a-select-option :key="2">SSI</a-select-option>
              </a-select>
            </a-form-model-item>
          </div>
          <div class="flex-vw">
            <a-form-model-item label="问卷编程" prop="programmer_id" ref="programmer_id">
              <a-select v-model="form.programmer_id" placeholder="请选择问卷编程">
                <a-select-option v-for="item in programmerData" :key="item.id">{{ item.admin_name }} </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="销售" prop="salesman_id" ref="salesman_id" class="cus-left">
              <a-select v-model="form.salesman_id" placeholder="请选择销售">
                <a-select-option v-for="item in salesmanData" :key="item.id">{{ item.admin_name }} </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="DP" prop="dp_id" ref="dp_id" class="cus-left">
              <a-select v-model="form.dp_id" placeholder="请选择DP">
                <a-select-option v-for="item in dpData" :key="item.id">{{ item.admin_name }} </a-select-option>
              </a-select>
            </a-form-model-item>
          </div>
          <div class="flex-vw">
            <a-form-model-item label="PM" prop="project_manager" ref="project_manager">
              <a-select v-model="form.project_manager" placeholder="请选择PM">
                <a-select-option v-for="item in projectManagerData" :key="item.id">{{ item.admin_name }} </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="研究" prop="researcher" ref="researcher" class="cus-left">
              <a-select v-model="form.researcher" placeholder="请选择研究">
                <a-select-option v-for="item in researcherData" :key="item.id">{{ item.admin_name }} </a-select-option>
              </a-select>
            </a-form-model-item>
          </div>
          <div class="flex-vw">
            <a-form-model-item label="货币类型" prop="currency_type" ref="currency_type">
              <a-select v-model="form.currency_type" placeholder="请选择货币类型">
                <a-select-option :key="1">人民币</a-select-option>
                <a-select-option :key="2">美元</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="项目单价" prop="unit_price" ref="unit_price" class="cus-left">
              <a-input-number v-model="form.unit_price" size="large" :min="0" :default-value="0"/>
            </a-form-model-item>
          </div>
          <a-form-model-item label="合同样本量" prop="sample_count" ref="sample_count">
            <a-input-number v-model="form.sample_count" size="large" :min="0" :default-value="0" style="width:150px"/>
          </a-form-model-item>
          <!-- <a-form-model-item label="项目主题" prop="project_subject" ref="project_subject">
            <a-input v-model="form.project_subject" placeholder="请输入项目主题"/>
          </a-form-model-item> -->
          <div class="flex-vw">
            <a-form-model-item label="客户名称" prop="customer_name" ref="customer_name">
              <customer-list :customer_name.sync="form.customer_name" :customer_id.sync="form.customer_id" @selectedHandler="selectCustomer"></customer-list>
            </a-form-model-item>
            <!-- <a-form-model-item label="联系手机号" prop="customer_contacts" ref="customer_contacts" style="margin-left: 180px;">
              <a-input v-model="form.customer_contacts" placeholder="请输入客户联系手机号" style="width:200px"/>
            </a-form-model-item> -->
          </div>
          <a-form-model-item label="Cookie限制" prop="cookie" ref="cookie">
            <a-radio-group v-model="form.cookie_limit" style="margin-left: 11px; width: 1500px">
              <a-radio value="0">无限制</a-radio>
              <a-radio value="1">接收到参数限制cookie</a-radio>
              <a-radio value="2">未接收到参数限制cookie参加三次</a-radio>
              <a-radio value="3">全部</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="IP限制" prop="ip_limit" ref="ip_limit">
            <a-radio-group v-model="form.ip_limit" style="margin-left: 11px; width: 1500px">
              <a-radio value="0">无限制</a-radio>
              <a-radio value="1">接收到参数限制IP</a-radio>
              <a-radio value="2">未接收到参数限制IP参加三次</a-radio>
              <a-radio value="3">全部</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="限制用户名" prop="member_limit" ref="member_limit">
            <a-radio-group v-model="form.member_limit" style="margin-left: 11px; width: 1500px">
              <a-radio value="0">无限制</a-radio>
              <a-radio value="1">接收到参数限用户名</a-radio>
              <a-radio value="2">未接收到参数限制用户名参加三次</a-radio>
              <a-radio value="3">全部</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="问卷有效时间" prop="validate_time" ref="validate_time">
            <a-input-number v-model="form.validate_time" size="large" :min="0" :default-value="0"/>
            <span> 秒(*0表示无时间限制)</span>
          </a-form-model-item>
          <a-form-model-item label="邀请方式" prop="display_form" ref="display_form">
            <a-radio-group v-model="form.display_form" style="margin-left: 11px; width: 800px">
              <a-radio value="1">开放外包</a-radio>
              <a-radio value="2">移动端显示</a-radio>
              <a-radio value="4">只允许移动端作答</a-radio>
              <a-radio value="8">只允许PC端作答</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="邀请通道" prop="channle" ref="channle">
            <a-select v-model="form.channle" placeholder="请选择邀请通道">
              <a-select-option :key="1">网站</a-select-option>
            </a-select>
          </a-form-model-item>
          <div class="flex-vw">
            <a-form-model-item label="奖励现金" prop="coin" ref="coin">
              <a-input-number v-model="form.coin" size="large" :min="0" :default-value="0"/>
              <span> 元</span>
            </a-form-model-item>
            <a-form-model-item label="奖励发放方式" prop="pay_type" ref="pay_type" class="cus-left">
              <a-select v-model="form.pay_type" placeholder="选择发放方式">
                <a-select-option :key="1">即时发放</a-select-option>
                <a-select-option :key="2">审核发放</a-select-option>
              </a-select>
            </a-form-model-item>
          </div>
          <a-form-model-item label="限制配额数" prop="quota_limit_count" ref="quota_limit_count" style="width: 1000px">
            <a-input-number v-model="form.quota_limit_count" size="large" :min="0" :default-value="0"/>
            <span> 个(为0不启用该功能)</span>
          </a-form-model-item>
          <a-form-model-item label="结束日期" prop="close_date" ref="close_date">
            <a-date-picker
              v-model="form.close_date"
              @change="onChangeDate"
              placeholder="请选择结束日期"
            />
          </a-form-model-item>
          <div class="tips-div">
            <div class="tips-title">温馨提示</div>
            <div class="tips-list">
              <a-checkbox-group
                :value="topTipsValue"
                @change="topTipChange">
                <a-row>
                  <a-col :span="15">
                    <a-checkbox v-for="(item, index) in topTipsData" :key="index" :value="item">{{ item }}</a-checkbox>
                  </a-col>
                </a-row>
              </a-checkbox-group>
            </div>
          </div>
        </a-form-model>
      </template>
      <a-button v-if="!projectDataInfo.project_status || projectDataInfo.project_status<=2" type="save" class="save-button" @click="onSubmit" :loading="loading">保存</a-button>
    </div>
  </div>
</template>

<script>
import { fetchAdminlist } from "@/api/admin";
import { createProject, fetchProjectInfo, updateProject, updatefilter } from "@/api/project";

export default {
  name: "ProjectDetail",
  components: {
    "project-filter": resolve =>
      require(["@/components/model/ProjectFilter.vue"], resolve),
    "customer-list": resolve =>
      require(["@/components/model/CustomerList.vue"], resolve),
    "table-title": resolve =>
      require(["@/components/table/TableTitle.vue"], resolve),
  },
  data() {
    return {
      loading: false,
      projectId: undefined,
      filterData: undefined,
      urlTypeData: [],
      projectFormData: [],
      projectTypeData: [],
      /// 编程信息
      programmerData: [],
      /// 销售
      salesmanData: [],
      dpData: [],
      projectManagerData: [],
      researcherData: [],
      topTipsData: [],
      topTipsValue: [],
      projectDataInfo: {},
      form: {
        project_title: undefined,
        sample_type: 1,
        url_type: undefined,
        project_form: undefined,
        project_type: undefined,
        project_url_type: 1,
        currency_type: undefined,
        unit_price: 0,
        sample_count: 0,
        salesman_id: undefined,
        dp_id: undefined,
        programmer_id: undefined,
        project_manager: undefined,
        researcher: undefined,
        // project_subject: undefined,
        customer_id: undefined,
        customer_name: undefined,
        // customer_contacts: undefined,
        cookie_limit: "0",
        ip_limit: "0",
        member_limit: "0",
        validate_time: 0,
        display_form: undefined,
        channle: 1,
        coin: 0,
        pay_type: undefined,
        quota_limit_count: 0,
        close_date: undefined,
      },
      rules: {
      },
    };
  },
  created() {
    this.projectId = this.$route.query.projectId;
    this.urlTypeData = urlTypeData;
    this.projectFormData = projectFormData;
    this.projectTypeData = projectTypeData;
    this.topTipsData = topTipsData;
    this.topTipsValue = topTipsData;
    this.initData();
  },
  methods: {
    initData() {
      this.fetchNormalData(4);
      this.fetchNormalData(3);
      this.fetchNormalData(7);
      this.fetchNormalData(5);
      this.fetchNormalData(6);
      if (this.projectId != undefined) {
        this.fetchProjectDetail()
      }
    },
    /// 获取管理员信息
    async fetchNormalData(role) {
      try {
        let res = await fetchAdminlist({
          role: role,
        });
        if (role == 4) { /// 编程信息
          this.programmerData = res.admin_list;
        }else if (role == 3) { /// 销售
          this.salesmanData = res.admin_list;
        }else if (role == 7) { /// DP
          this.dpData = res.admin_list;
        }else if (role == 5) { /// 项目经理
          this.projectManagerData = res.admin_list;
        }else if (role == 6) { /// 研究
          this.researcherData = res.admin_list;
        }
      } catch (error) {
        console.log(error);
      }
    },
    /// 筛选
    filterHandler(e) {
      this.filterData = e;
      if (this.projectDataInfo.project_status == 3) {
        this.updatefilter(e);
      }
    },
    /// 温馨提示
    topTipChange(e) {
      this.topTipsValue = e;
    },
    /// 选择客户
    selectCustomer(e) {
      this.form.customer_id = e;
    },
    /// 更改日历
    onChangeDate(date, dateString) {
      this.form.close_date = dateString + " 00:00:00"
    },
    /// 获取项目详情
    async fetchProjectDetail() {
      try {
        let res = await fetchProjectInfo({
          project_id: this.projectId
        });
        this.projectDataInfo = res;
        this.filterData = res.sample_condition;
        this.form.project_title = res.project_title;
        this.form.sample_type = res.sample_type;
        this.form.url_type = res.url_type;
        this.form.project_form = res.project_form;
        this.form.project_type = res.project_type;
        this.form.project_url_type = res.project_url_type;
        this.form.programmer_id = res.programmer_id;
        this.form.salesman_id = res.salesman_id;
        this.form.dp_id = res.dp_id;
        this.form.project_manager = res.dp_id;
        this.form.researcher = res.researcher;
        this.form.currency_type = res.currency_type;
        this.form.unit_price = res.unit_price;
        this.form.sample_count = res.sample_count;
        // this.form.project_subject = res.project_subject;
        this.form.customer_id = res.customer_id;
        this.form.customer_name = res.customer_name;
        // this.form.customer_contacts = res.customer_contacts;
        this.form.cookie_limit = res.cookie_limit + '';
        this.form.ip_limit = res.ip_limit + '';
        this.form.member_limit = res.member_limit + '';
        this.form.validate_time = res.validate_time;
        this.form.channle = res.channel;
        this.form.quota_limit_count = res.quota_limit_count;
        this.form.display_form = res.display_form + '';
        this.form.coin = res.coin;
        this.form.pay_type = res.pay_type;
        this.form.close_date = res.close_date;
        this.topTipsValue = res.project_tips.split(",");
      } catch (error) {
        console.log(error)
      }
    },
    /// 创建项目
    onSubmit() {
      if (!this.form.project_title) {
        this.$message.error("请输入项目标题")
        return
      }
      if (!this.form.sample_type) {
        this.$message.error("请选择抽样类型")
        return
      }
      if (!this.form.url_type) {
        this.$message.error("请选择链接类型")
        return
      }
      if (!this.form.project_form) {
        this.$message.error("请选择项目形式")
        return
      }
      if (!this.form.project_type) {
        this.$message.error("请选择项目类型")
        return
      }
      if (!this.form.programmer_id) {
        this.$message.error("请选择问卷编程")
        return
      }
      if (!this.form.salesman_id) {
        this.$message.error("请选择销售")
        return
      }
      if (!this.form.dp_id) {
        this.$message.error("请选择DP")
        return
      }
      if (!this.form.project_manager) {
        this.$message.error("请选择PM")
        return
      }
      if (!this.form.researcher) {
        this.$message.error("请选择研究")
        return
      }
      if (!this.form.currency_type) {
        this.$message.error("请选择货币类型")
        return
      }
      if (this.form.unit_price <= 0) {
        this.$message.error("请输入项目单价")
        return
      }
      if (this.form.sample_count <= 0) {
        this.$message.error("请输入合同样本量")
        return
      }
      // if (!this.form.project_subject) {
      //   this.$message.error("请输入项目主题")
      //   return
      // }
      /// 接入后根绝客户id进行判断
      if (!this.form.customer_id) {
        this.$message.error("请选择客户")
        return
      }
      // if (!this.form.customer_contacts) {
      //   this.$message.error("请输入客户联系手机号")
      //   return
      // }
      // if (!PHONE_REG.test(this.form.customer_contacts)) {
      //   return this.$message.error("请输入正确的客户联系手机号");_
      // }
      if (!this.form.display_form) {
        this.$message.error("请选择邀请方式")
        return
      }
      if (this.form.coin <= 0) {
        this.$message.error("请输入奖励现金")
        return
      }
      if (!this.form.pay_type) {
        this.$message.error("请输入奖励发放方式")
        return
      }
      if (!this.form.close_date) {
        this.$message.error("请选择结束日期")
        return
      }
      this.creatProjectData()
    },
    async creatProjectData() {
      const that = this;
      this.loading = true;
      try {
        let params = {
          sample_condition: that.filterData,
          project_title: that.form.project_title,
          sample_type: that.form.sample_type,
          url_type: that.form.url_type,
          project_form: that.form.project_form,
          project_type: that.form.project_type,
          project_url_type: this.form.project_url_type,
          programmer_id: that.form.programmer_id,
          salesman_id: that.form.salesman_id,
          dp_id: that.form.dp_id,
          project_manager: that.form.project_manager,
          researcher: that.form.researcher,
          currency_type: that.form.currency_type,
          unit_price: that.form.unit_price,
          sample_count: that.form.sample_count,
          // project_subject: that.form.project_subject,
          customer_id: that.form.customer_id,
          // customer_contacts: that.form.customer_contacts,
          cookie_limit: that.form.cookie_limit - 0,
          ip_limit: that.form.ip_limit - 0,
          member_limit: that.form.member_limit - 0,
          validate_time: that.form.validate_time,
          channel: that.form.channle,
          quota_limit_count: that.form.quota_limit_count,
          display_form: that.form.display_form - 0,
          coin: that.form.coin,
          pay_type: that.form.pay_type,
          close_date: that.form.close_date,
          project_tips: that.topTipsValue.join(","),
        }
        if (this.projectId == undefined) {
          await createProject(params);
          this.$message.success("项目创建成功");
        }else {
          params["project_id"] = this.projectId - 0;
          await updateProject(params);
          this.$message.success("项目更新成功");
        }
        this.loading = false; 
        setTimeout(() => {
          if (that.projectId == undefined) {
            that.$router.push({ name: "project"});
          }else {
            that.$router.go(-1);
          }
        }, 500);
      } catch (error) {
        this.loading = false;
        console.log(error)
      }
    },
    /// 上传文件
    uploadhandleChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        this.$message.success(`${info.file.name} 上传完成`);
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} 上传失败`);
      }
    },
    /// 更新筛选项
    async updatefilter(filter) {
      const that = this;
      let res = await updatefilter({
        project_id: this.projectId - 0,
        sample_condition: filter,
      });
      this.$message.success("筛选项更新成功");
      setTimeout(() => {
        that.$router.go(-1);
      }, 500);
    }
  },
};
const PHONE_REG = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
/// 链接形式
const urlTypeData = [
  {"id": 1, "name": "单链接"}, {"id": 2, "name": "多链接"}
];
/// 项目形式
const projectFormData = [
  {"id": 1, "name": "样本项目"}, {"id": 2, "name": "产品使用"}
];
/// 项目形式
const projectTypeData = [
  {"id": 1, "name": "Full Service"}, {"id": 2, "name": "Sample only"}, 
  {"id": 3, "name": "End Client"}, {"id": 4, "name": "API"},
  {"id": 5, "name": "非盈利调查"}
];

/// 温馨提示
const topTipsData = ['本次调查首页打开速度比较慢，需耐心等待~', '本调查项目在客户的问卷系统上执行，如出现问卷页面跳转到其他网站，属于正常情况',
                     '本调查项目的打开速度可能较慢，答题时请耐心等待页面刷新', "本调查项目不适配手机屏幕，手机答题体验较差，推荐您用电脑登录网站参加本调查"];

</script>
